/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import loadingAnimation from "../../loadingAnimation2.json";
import loadingAnimation2 from "../../loading_spinner_editted_noCanvas3.svg";
import playButtonIcon from "../../assets/img/play-button.png";
import ManageFavoriteModal from "./ManageFavoriteModal";
import defaultVideoThumbnail from "../../assets/img/default_video_thumbnail.jpeg";
import "react-multi-email/dist/style.css";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import { NEW_CUSTOM_METADATA } from "../../services/graphql/pimcoreDam";
import { useAppDispatch, useAppSelector } from "../../store";
import { ModalProps } from "../../types/common";
import {
  getAssetColorSpace,
  getAssetResolution,
  getAssetThumbnailUrl,
  getAssetTypeByCustomMetaDataId,
  getDocumentThumbnailUrl,
  getVideoPreviewFilePath,
  getVideoPreviewThumbnails,
  removeAssetsFromFavorite,
} from "../../utils/catalog";
import { toggleChoice, toggleIsOpen } from "../../pages/shareSlice";
import {
  toggleDownloadIsOpen,
  toggleDownloadChoice,
} from "../../pages/downloadSlice";
import { RootState } from "../../rootReducer";
import {
  addToFavoriteFolder,
  removeFavoriteFromFolder,
} from "../../pages/favoriteSlice";
import http from "../../services/api";
import { assetId } from "../../pages/assetCardSlice";
import {
  FavoriteInfoInterface,
  setFavorite,
} from "../../pages/newFavoriteSlice";

interface FeaturesInterface {
  id: number;
  name: string;
  selections?: string[] | null;
  date?: string | null;
  text?: string | null;
  number?: number | null;
}

interface CustomeMetadataAttriibutesRequestInterface {
  local: string;
  features: FeaturesInterface[];
}

interface CustomeMetadataAttriibutesResponse {
  [details: string]: string;
}

const AssetDetailModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
  fullscreen,
}: ModalProps) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [asset, setAsset] = useState<any | null>();
  const userId = useSelector((state: RootState) => state.user?.id);
  const Favorites = useAppSelector(
    (state: RootState) => state.newFavorite.favorite
  );
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const [showSidebar, setShowSidebar] = useState(true);
  const [videoFilePath, setVideoFilePath] = useState<string | null>(null);
  const [videoThumbnail, setVideoThumbnail] = useState<string | null>(
    defaultVideoThumbnail
  );
  const [colorSpace, setColorSpace] = useState<string | null>(null);
  const [documentFilePath, setDocumentFilePath] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [assetResolution, setAssetResolution] = useState<string | null>("");
  const [customMetaDataId, setCustomMetaDataId] = useState<string | null>(null);
  const [regions, setRegions] = useState<string | null>(null);
  const [assetTypeName, setAssetTypeName] = useState<string | null>(null);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const [isAddToFavoriteModalTrigger, setIsAddToFavoriteModalTrigger] =
    useState(false);

  interface CustomMetadataAttributesInterface {
    [details: string]: string;
  }

  const [customMetaDataAttributes, setCustomMetaDataAttributes] =
    useState<CustomMetadataAttributesInterface | null>(null);

  const assetValue = useAppSelector((state) => state.assetCard);
  const [
    currentlyManipulatedFolderIndexTemp,
    setCurrentlyManipulatedFolderIndexTemp,
  ] = useState<number>(-1);
  const [isFavoriteChecked, setIsFavoriteChecked] = useState<boolean>(false);

  // update user favorite folder pimcore
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO, {
    onCompleted({ updateUser: { success } }) {
      if (success) {
        if (isFavoriteChecked) {
          dispatch(
            addToFavoriteFolder({
              assetID: Number(assetValue.id),
              folderIndex: currentlyManipulatedFolderIndexTemp,
            })
          );
        } else {
          dispatch(
            removeFavoriteFromFolder({
              assetID: Number(assetValue.id),
              folderIndex: currentlyManipulatedFolderIndexTemp,
            })
          );
        }
      }
    },
  });

  const [getCustomMetadata, { loading, error, data }] = useLazyQuery(
    NEW_CUSTOM_METADATA,
    { fetchPolicy: "network-only" }
  );

  const setRegionValues = useCallback((value: any) => {
    let temp = "";
    const region = value?.getCustomMetadata?.region;
    if (region) {
      region.forEach((item: any, index: number) => {
        if (index === 0) temp = item.name.toString();
        else temp += `, ${item.name}`;
      });
      setRegions(temp);
    }
  }, []);

  const toggleManageFavoriteModal = () => {
    setIsAddToFavoriteModalTrigger(true);
    setShowManageFavorite(!showManageFavorite);
  };

  useEffect(() => {
    if (data) setRegionValues(data);
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setCustomMetaDataAttributes(null);
      setCustomMetaDataId(null);
      setAssetResolution(null);
      setAsset(null);
      setColorSpace(null);
      setRegions(null);
      setAssetTypeName(null);
      dispatch(assetId(null));
      setDocumentFilePath("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !loading &&
      data &&
      isOpen &&
      assetValue.id &&
      data?.getCustomMetadata?.customAttributes[0]?.features
    ) {
      const path = "/api/getcustomattributesoptionsnames";

      const features: FeaturesInterface[] =
        data?.getCustomMetadata?.customAttributes[0]?.features;

      http
        .post<
          CustomeMetadataAttriibutesRequestInterface,
          CustomeMetadataAttriibutesResponse
        >(path, { local: "en_US", features }, { cancelToken: source.token })
        .then((res) => {
          if (res) {
            const temp: CustomMetadataAttributesInterface = {};
            Object.keys(res).forEach((key) => {
              if (res[key] !== "" && res[key] !== null) {
                temp[key] = res[key];
              }
            });
            setCustomMetaDataAttributes(temp);
          }
        })
        .catch((err) => {});
    }
    return () => {
      source.cancel("Component was destroyed!!!");
    };
  }, [loading, data]);

  useEffect(() => {
    if (!isOpen) {
      source.cancel("Component was destroyed!!!");
    }
  }, [isOpen]);

  useEffect(() => {
    if (assetValue.id) {
      setAsset({
        id: assetValue.id,
        filename: assetValue.filename,
        fullpath: assetValue.fullpath,
        fullpathThumbnailPortraitImage:
          assetValue.fullpathThumbnailPortraitImage,
        fullpathThumbnailLandscapeImage:
          assetValue.fullpathThumbnailLandscapeImage,
        fullpathExtandPortraitImage: assetValue.fullpathExtandPortraitImage,
        fullpathExtandLandscapeImage: assetValue.fullpathExtandLandscapeImage,
        fullpathThumbnailFont: assetValue.fullpathThumbnailFont,
        fullpathExtandFont: assetValue.fullpathExtandFont,
        youTubeSDPath: assetValue.youTubeSDPath,
        type: assetValue.type,
        filesize: assetValue.fileSize,
        creationDate: assetValue.creationDate,
        customMetadataId: assetValue.customMetadataId,
        dpiAsset: assetValue.dpiAsset,
      });
      getAssetColorSpace(assetValue.id as string, setColorSpace, source);
    }
  }, [assetValue]);

  useEffect(() => {
    if (asset) {
      getAssetResolution({ assetId: Number(asset.id) }, setAssetResolution);
      setFileExtension(
        asset?.filename?.split(".").slice(-1).pop().toUpperCase()
      );
    }
  }, [asset]);

  useEffect(() => {
    if (asset && asset.customMetadataId) {
      setCustomMetaDataId(asset.customMetadataId);
    }
    if (asset && !asset.customMetadataId) {
      setCustomMetaDataId(null);
    }
  }, [asset]);

  useEffect(() => {
    if (customMetaDataId && isOpen) {
      getCustomMetadata({ variables: { id: Number(customMetaDataId) } });
      getAssetTypeByCustomMetaDataId(customMetaDataId, setAssetTypeName);
    }
  }, [customMetaDataId, isOpen]);

  const toggleShowSidebar = () => setShowSidebar(!showSidebar);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  function findFolderId(
    json: Record<string, any>,
    assetID: string
  ): string | null {
    const folderIds = Object.keys(json);
    const folderId = folderIds.find((ID) => {
      const folder = json[ID];
      return folder.assets.some(
        (Asset: { id: string }) => Asset.id === assetID
      );
    });
    return folderId || null;
  }

  const handleHeartIcon = (assetIDValue: string) => {
    const allFavoriteIds: string[] = [];
    Object.values(Favorites).forEach((folder) => {
      folder.assets.forEach((assetItem) => {
        allFavoriteIds.push(String(assetItem.id));
      });
    });

    if (allFavoriteIds.includes(String(assetValue.id))) {
      const folderId = findFolderId(Favorites, assetIDValue);
      if (folderId) {
        removeAssetsFromFavorite(
          userId,
          [assetIDValue],
          folderId,
          folderId,
          (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
        );
      }
    } else {
      toggleManageFavoriteModal();
    }
  };

  const SidebarContent = () => (
    <div id="mySidenav" className="sidenav">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              {t("General")}
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
          >
            <div className="accordion-body">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="title">{t("File Size")}</td>
                      <td className="desc">
                        {(asset.filesize / (1024 * 1024)).toFixed(2)} MB
                      </td>
                    </tr>
                    <tr>
                      <td className="title">{t("File Type")}</td>
                      <td className="desc">{fileExtension}</td>
                    </tr>
                    {assetResolution && (
                      <tr>
                        <td className="title">{t("Dimensions")}</td>
                        <td className="desc">{`${assetResolution} px`}</td>
                      </tr>
                    )}
                    {assetValue.dpiAsset !== null && (
                      <tr>
                        <td className="title">{t("Resolution")}</td>
                        <td className="desc">{`${assetValue.dpiAsset} dpi`}</td>
                      </tr>
                    )}
                    {colorSpace && (
                      <tr>
                        <td className="title">{t("Color Space")}</td>
                        <td className="desc">{colorSpace}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="title">{t("Uploaded Date")}</td>
                      <td className="desc">
                        {new Date(asset.creationDate * 1000).toDateString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              {t("Details")}
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingTwo"
          >
            <div className="accordion-body">
              <div className="table-responsive">
                {loading ? (
                  <object
                    type="image/svg+xml"
                    width={15}
                    height={15}
                    data={loadingAnimation2}
                  >
                    svg-animation
                  </object>
                ) : (
                  <table className="table">
                    <tbody>
                      {assetTypeName && (
                        <tr>
                          <td className="title">{t("Asset Type")}</td>
                          <td className="desc">{assetTypeName}</td>
                        </tr>
                      )}
                      {data?.getCustomMetadata?.brand?.name && (
                        <tr>
                          <td className="title">{t("Brand")}</td>
                          <td className="desc">
                            {data?.getCustomMetadata?.brand?.name}
                          </td>
                        </tr>
                      )}
                      {data?.getCustomMetadata?.description && (
                        <tr>
                          <td className="title">{t("Description")}</td>
                          <td className="desc">
                            {data?.getCustomMetadata?.description}
                          </td>
                        </tr>
                      )}
                      {regions && (
                        <tr>
                          <td className="title">{t("Regions")}</td>
                          <td className="desc">{regions}</td>
                        </tr>
                      )}
                      {customMetaDataAttributes &&
                        Object.keys(customMetaDataAttributes).map((key) => (
                          <tr>
                            <td className="title">{t(key)}</td>
                            <td className="desc">
                              {customMetaDataAttributes[key]}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    setVideoFilePath(null);
    setVideoThumbnail(defaultVideoThumbnail);
    if (asset && asset.type === "video") {
      getVideoPreviewFilePath(asset.id as string, setVideoFilePath);
      getVideoPreviewThumbnails(asset.id as string, setVideoThumbnail);
    } else if (asset && asset.type === "document") {
      getDocumentThumbnailUrl(asset.id as string, setDocumentFilePath);
    }
  }, [asset]);

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    const preview = assetValue.youTubeSDPath
      ? (assetValue.youTubeSDPath as string).slice(0, 5) !== "https"
        ? `https://gildan.blob.core.windows.net/gildan/tmp/video-thumbnails${
            assetValue.youTubeSDPath as string
          }`
        : (assetValue.youTubeSDPath as string)
      : "";
  }, [assetValue]);

  const ModalBody = () => {
    const allFavoriteIds: string[] = [];
    Object.values(Favorites).forEach((folder) => {
      folder.assets.forEach((assetItem) => {
        allFavoriteIds.push(String(assetItem.id));
      });
    });
    const location = useLocation();
    const tempPath = location.pathname.split("/");
    const pathname = tempPath[tempPath.length - 1];
    const thumbnailUrl = getAssetThumbnailUrl(asset, "extend", pathname);
    const assetType = asset && asset.type;

    return (
      <>
        <Modal.Header>
          <h4 className="modal-title" id="exampleModalLabel">
            {asset.filename}
          </h4>
          <div className="asset-modal-header-buttons">
            <button
              type="button"
              className="btn-download"
              aria-label="Download"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Download"
              onClick={() => {
                dispatch(toggleDownloadIsOpen());
                dispatch(toggleDownloadChoice(0));
              }}
            />
            <button
              type="button"
              className="btn-share"
              aria-label="Share"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Share"
              onClick={() => {
                dispatch(toggleChoice(1));
                dispatch(toggleIsOpen());
              }}
            />
            <button
              type="button"
              className={`${
                allFavoriteIds.includes(String(assetValue.id))
                  ? "btn-favorite-fill"
                  : "btn-favorite"
              }`}
              aria-label="Favorite"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Favorite"
              onClick={() => {
                if (assetValue.id) {
                  handleHeartIcon(assetValue.id);
                }
              }}
            />
            <button
              type="button"
              className={`btn-info ${showSidebar ? "active" : ""}`}
              aria-label="Info"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Info"
              onClick={toggleShowSidebar}
            />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            />
          </div>
        </Modal.Header>
        <Modal.Body id="main">
          <div className="asset-content" id="content">
            {/* eslint-disable-next-line no-nested-ternary */}
            {((fileExtension === "MP4" || fileExtension === "MOV") &&
              (videoThumbnail as string)) ||
            (fileExtension === "WEBM" && (videoThumbnail as string)) ? (
              videoThumbnail !== defaultVideoThumbnail &&
              assetValue.youTubeSDPath ? (
                <ReactPlayer
                  url={[
                    {
                      src: encodeURI(
                        assetValue.youTubeSDPath
                          ? (assetValue.youTubeSDPath as string)
                          : ""
                      ),
                      type: "video/mp4",
                    },
                  ]}
                  // url={videoFilePath}
                  width="90%"
                  height="90%"
                  controls
                  playing
                  light={encodeURI(assetValue.youTubeSDThumbnailPath as string)}
                  playIcon={<img src={playButtonIcon} alt="videoPlayer" />}
                />
              ) : (
                <img
                  src={defaultVideoThumbnail}
                  width="90%"
                  height="90%"
                  alt="Gildan Thumbnail"
                />
              )
            ) : fileExtension === "PDF" && documentFilePath.length > 0 ? (
              <img
                className="img-fluid"
                src={documentFilePath}
                alt="ImagePDF"
              />
            ) : fileExtension === "PNG" ||
              fileExtension === "JPG" ||
              fileExtension === "TIF" ? (
              <img
                className="img-fluid"
                src={thumbnailUrl}
                alt="ImageDEFAULT"
              />
            ) : null}
          </div>
          {showSidebar && <SidebarContent />}
        </Modal.Body>
      </>
    );
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={toggle}
        className={`${modalClass}`}
        backdrop={backdrop}
        centered={centered}
        keyboard={keyboard}
        fullscreen={fullscreen || undefined}
      >
        {asset ? <ModalBody /> : <Modal.Body id="main" />}
      </Modal>
      <ManageFavoriteModal
        isOpen={showManageFavorite}
        toggle={toggleManageFavoriteModal}
        modalClass="favoritesModal"
        centered
        backdrop="static"
        keyboard
        isAddToFavorite={isAddToFavoriteModalTrigger}
      />
    </>
  );
};

export default AssetDetailModal;
